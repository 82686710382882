.plans-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -4rem;
    padding: 0 2rem;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}



.plans-card{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}
.plans-blur-1{
    width: 23rem;
    height: 23rem;
    top: 6rem;
    left: 0rem;
}
.plans-blur-2{
    width: 32rem;
    height: 23rem;
    top: 10rem;
    left: 0rem;
}
.plans{
    display: flex;
    flex-direction: column;
    background: var(--caloryCard);
    color: white;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
}

.plans a{
    color: black;
}

.plans:nth-child(3){
    background: var(--planCard);
    transform: scale(1.1);
}
.plans:nth-child(3)>:nth-child(1){
    fill: white;
}
.plans:nth-child(3)>:nth-child(6){
    color: var(--orange);
}
.plans>svg{
    fill: var(--orange);
    width: 2rem;
    font-weight: 2rem;
}

.plans>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}

.plans>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}

.plans>:nth-child(4){
    display: flex;
    gap: 1rem;
    flex-direction: column;
}
.plans>:nth-child(5){
    font-size: 0.8rem;
}
.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.feature>img{
    width: 1rem;
}

@media screen and (max-width:768px ){
    .plans-card{
        flex-direction: column;
        
    }
    .plans>:nth-child(2){
        transform: none;
    }
}


