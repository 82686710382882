.Footer-container {
    position: relative;
}

.Footer-container > hr {
    border: 1px solid var(--lightgray);
}

.footer {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    height: auto;
}

.social-links {
    display: flex;
    gap: 2rem;
}

.social-links img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.logo-f > img {
    width: 10rem;
}

.gym-info {
    text-align: center;
}

.gym-info h2 {
    margin: 0;
    font-size: 1.5rem;
    color: white; /* Change to white */
}

.gym-info p {
    margin: 0;
    font-size: 1rem;
    color: white; /* Change to white */
}

.blur-f-1 {
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: red; /* Original color */
}

.blur-f-2 {
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: rgb(255, 115, 0); /* Original color */
}